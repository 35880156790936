<template>
  <div class="container">
    <div>
      <div v-if="emptyFlag">
        <van-empty />
      </div>
      <div v-else>
        <div v-for="(item, index) in listTwo" :key="index+'noshuaxin'" class="card_box">
          <!-- 头部信息 -->
          <div class="myInformation">
            <!-- 头像 -->
            <div class="headPortrait" @click="openReport(item.memberId)">
              <img v-if="item.headPhoto" :src="item.headPhoto" alt="" />
              <img v-else src="../../assets/Headportrait.png" alt="" />
            </div>
            <div class="nameAndTime">
              <p>{{ item.memberName }}</p>
              <p class="smallTime">{{ item.createTime }}</p>
            </div>
            <!-- 审核按钮 -->
            <div v-if="myShow" class="">
              <!-- <i v-if="iosBlock" class="iconfont icon-guanbi"></i>
              <span v-if="iosBlock" @click="onClickReport">举报</span> -->
              <van-button type="danger" round size="small" v-if="item.status === 1"
                >审核不通过</van-button
              >
              <van-button type="primary" round size="small" v-else-if="item.status === 2"
                >审核通过</van-button
              >
              <van-button type="info" round size="small" v-else-if="item.status === 3"
                >待审核</van-button
              >
            </div>
          </div>

          <!-- 文字内容 -->
          <div class="textContent" v-if="item.textContent" @click="enterDetails(item)">
            {{ item.textContent }}
          </div>

          <!-- 视频 -->
          <!-- {{item.videoUrl}} -->
          <!-- <div @click="vedioOpenPlay(item, index)"> 范德萨</div> -->
          <div
            class="vedioStyleBox"
            v-if="JSON.parse(item.videoUrl) && JSON.parse(item.videoUrl).length > 0"
          >
            <div class="" v-for="(vdm, vdx) in JSON.parse(item.videoUrl)" :key="vdx + item.ugcId + 'kk'">
              <div class="coverImg" v-if="item.thumbBlock" @click="vedioOpenPlay(item, index)">
                <img class="thumbImg" src="../../assets/bvd.png" alt />
                <img
                  class="vedioButtonSty"
                  src="../../assets/vedioSing.png"
                  alt
                />
              </div>
              <video
                class="vedioContent"
                playsinline
                webkit-playsinline
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                :src="vdm.url"
                controls
                :id="'datailVedio' + index"
              ></video>
            </div>
          </div>
          <!-- 音频 -->
          <div
            class="audioContentBox"
            v-if="JSON.parse(item.audioUrl) && JSON.parse(item.audioUrl).length > 0"
          >
            <div class="v_for" v-for="(adm, adx) in JSON.parse(item.audioUrl)" :key="adx">
              <div>
                <img class="thumbImg" src="../../assets/vedioMoRen.png" alt />
                <img
                  v-if="item.thumbBlock"
                  @click="audioOpenPlay(item, index)"
                  class="vedioButtonSty"
                  src="../../assets/vedioSing.png"
                  alt
                />
                <img
                  v-else
                  @click="audioOpenPlay(item, index)"
                  class="vedioButtonSty"
                  src="../../assets/vplay.png"
                  alt
                />
                <audio
                  class="vedioContent"
                  playsinline
                  webkit-playsinline
                  x5-video-player-type="h5"
                  x5-video-player-fullscreen="true"
                  :src="adm.url"
                  :id="'datailAudio' + index"
                ></audio>
              </div>
            </div>
          </div>

          <!-- 图片(必须是数组) -->
          <!-- {{item.picUrl}} -->
          <div v-if="JSON.parse(item.picUrl) && JSON.parse(item.picUrl).length > 0">
            <show-picture :pictureList="JSON.parse(item.picUrl)"></show-picture>
          </div>

          <!-- 位置 -->
          <div v-if="item.position" class="positionCon">
            <i class="iconfont icon-weizhi"></i>
            {{ item.position }}
          </div>
          <!-- 评论 -->
          <div class="oprationIcon">
            <div class="commentContent" @click="enterDetails(item)">点击查看评论详情</div>
            <!-- <i class="iconfont icon-fenxiang1" @click="shareData(item)"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
// import { Dialog, Toast } from "vant";
import ShowPicture from "./ShowPicture";
// import Comment from "./Comment";

export default {
  props: ["listContent", "isMy"],
  data() {
    return {
      emptyFlag: false,
      listTwo: [],
      iosBlock: false, // 是否为iOS界面
      reportPopWindow: false, // 举报弹窗
      actions: [{ name: "屏蔽" }, { name: "举报" }], // 举报弹窗中的数据
      myShow: false, // 我的页面显示审核按钮
      lastAudioIndex: -1,
      lastVedioIndex: -1,
    };
  },
  components: {
    ShowPicture,
    // Comment,
  },
  watch: {
    listContent: {
      handler: function (newVal) {
        for (var i = 0; i < newVal.length; i++) {
          this.$set(newVal[i],'thumbBlock',true);
        }
        this.listTwo = JSON.parse(JSON.stringify(newVal));
        // console.log(this.listTwo)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // var that = this;
    // this.setupWebViewJavascriptBridge(function (bridge) {
    //   bridge.registerHandler("stopPlay", function (data, responseCallback) {
    //     that.closeAudio();
    //     that.closeVedio();
    //     var responseData = { "Javascript Says": "Right back atcha!" };
    //     responseCallback(responseData);
    //   });
    // });
  },
  mounted() {
    // 判断是安卓还是ios
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    if (isAndroid) {
      this.iosBlock = false;
    } else {
      this.iosBlock = true;
    }
    if (this.listContent.length === 0) {
      this.emptyFlag = true;
    }
    if (this.isMy) {
      this.myShow = true;
    }
  },
  beforeDestroy() {
    if (this.lastAudioIndex >= 0) {
      this.closeAudio();
    }
    if (this.lastVedioIndex >= 0) {
      this.closeVedio();
    }
  },
  methods: {
    // 点击头像，打开举报弹窗
    // openReport() {
    //   if (this.iosBlock) {
    //     this.reportPopWindow = true;
    //   }
    // },
    // onSelect(item) {
    //   if (item.name === "举报") {
    //     Dialog.alert({
    //       message:
    //         "举报成功，平台将会在24小时之内给出回复。\n互联网违法和不良信息举报电话：0566-5021221\n邮箱：ahqybtv@qq.com",
    //       confirmButtonColor: "#468DE1",
    //     });
    //   }
    // },
    // // 点击举报按钮
    // onClickReport() {
    //   Dialog.alert({
    //     message:
    //       "举报成功，平台会在24小时内回复。\n互联网违法和不良信息举报电话：0566-5021221\n邮箱：ahqybtv@qq.com",
    //     confirmButtonColor: "#468DE1",
    //   });
    // },
    // 播放视频
    vedioOpenPlay(item, index) {
      if (index !== this.lastVedioIndex && this.lastVedioIndex >= 0) {
        this.closeVedio();
      }
      if (this.lastAudioIndex >= 0) {
        this.closeAudio();
      }
      const video = document.getElementById("datailVedio" + index);
      if (video) {
        if (video.paused) {
          item.thumbBlock = false;
          this.$forceUpdate();
          video.play();
        }
      }
      this.lastVedioIndex = index;
    },
    // 音频播放
    audioOpenPlay(item, index) {
      if (index !== this.lastAudioIndex && this.lastAudioIndex >= 0) {
        this.closeAudio();
      }

      if (this.lastVedioIndex >= 0) {
        this.closeVedio();
      }

      const audio = document.getElementById("datailAudio" + index);
      if (audio) {
        if (audio.paused) {
          item.thumbBlock = false;
          this.$forceUpdate();
          audio.play();
        } else {
          item.thumbBlock = true;
          this.$forceUpdate();
          audio.pause();
        }
        audio.addEventListener(
          "pause",
          () => {
            item.thumbBlock = true;
            this.$forceUpdate();
            audio.pause();
          },
          false
        );
      }
      this.lastAudioIndex = index;
    },
    // 进入动态详情
    enterDetails(item) {
      var link =
        window.location.href.split("#/")[0] + "#/interactionDetail?id=" + item.ugcId; //链接
      if (this.lastAudioIndex >= 0) {
        this.closeAudio();
      }
      if (this.lastVedioIndex >= 0) {
        this.closeVedio();
      }
      window.location.href = link;
    },
    // 关闭上一个播放的音药
    closeAudio() {
      const lastAudio = document.getElementById("datailAudio" + this.lastAudioIndex);
      if (lastAudio) {
        this.listTwo[this.lastAudioIndex].thumbBlock = true;
        this.$forceUpdate();
        lastAudio.pause();
      }
    },
    // 关闭上一个播放的视频
    closeVedio() {
      const lastVedio = document.getElementById("datailVedio" + this.lastVedioIndex);
      if (lastVedio) {
        this.listTwo[this.lastVedioIndex].thumbBlock = true;
        this.$forceUpdate();
        lastVedio.pause();
      }
    },
  },
};
</script>
<style>
.van-dialog {
  border-radius: 0;
}
</style>
<style lang="less" scoped>
.container {
  padding: 0 3vw;
  .card_box {
    .myInformation {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 14px 0 8px 0;
      .headPortrait {
        width: 30px;
        height: 30px;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .nameAndTime {
        margin-left: 5px;
        font-size: 13px;
        width: 50vw;
        .smallTime {
          font-size: 10px;
          color: #afafaf;
          margin-top: 2px;
        }
      }
      .screeningReport {
        width: 109px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        span {
          font-size: 14px;
          background: #006abf;
          color: #fff;
          padding: 2px 3px;
          border-radius: 3px;
        }
        .iconfont {
          font-size: 10px;
        }
      }
    }
    .textContent {
      width: 100%;
      font-size: 16px;
      word-break: break-all;
    }
    .vedioStyleBox {
      width: 100%;
      // height: 190px;
      position: relative;
      /* margin-bottom: 12px; */
      .coverImg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        .thumbImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .vedioButtonSty {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -18px;
          margin-top: -18px;
          z-index: 1000;
        }
      }
      .vedioContent {
        width: 100%;
        height: 190px;
        object-fit: contain;
      }
    }
    .audioContentBox {
      width: 100%;
      // margin-top: 7px;
      // height: 190px;
      position: relative;
      .v_for {
        position: relative;
        z-index: 1;
        .thumbImg {
          width: 100%;
          height: 190px;
        }
        .vedioButtonSty {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -18px;
          margin-top: -18px;
          z-index: 10;
        }
      }
      .vedioContent {
        width: 100%;
        height: 190px;
        object-fit: contain;
      }
    }
    .positionCon {
      font-size: 14px;
      color: #006abf;
      margin-bottom: 13.5px;
      margin-top: 5px;
      .iconfont {
        font-size: 14px;
      }
    }
  }
  /* 操作图标 */
  .oprationIcon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
    margin-bottom: 13.5px;
    .iconfont {
      margin-left: 30px;
      font-size: 25px;
    }
    .commentContent {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      background: #f3f7fa;
      margin-bottom: 13.5px;
      border-radius: 4px;
      color: #abaeb5;
    }
  }
  .commentC {
    width: 100%;
    //   margin-bottom: 55px;
    .columnTitle {
      text-align: center;
      padding: 5px 0;
    }
    .columnContent {
      height: calc(100% - 80px);
      li {
        padding: 10px 15px;
        overflow: hidden;
        display: flex;
        img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: #107da8;
          // padding: 3px;
          box-sizing: border-box;
        }
        .rootComment {
          width: calc(100% - 22px);
          padding: 0 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #efefef;
          padding-bottom: 10px;
          .userInfo {
            display: flex;
            justify-content: space-between;
            line-height: 22px;
            span {
              font-size: 12px;
              &:last-child {
                color: #bbb;
              }
            }
          }
          .replyBox {
            margin-top: 10px;
            padding: 3px 0 3px 15px;
            background: #efefef;
            color: #107da8;
          }
          p {
            font-size: 14px;
          }
          .childComment {
            overflow: hidden;
            display: flex;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #efefef;
            > div {
              width: calc(100% - 22px);
              padding-left: 10px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .noComment {
      text-align: center;
      height: 100px;
      line-height: 100px;
    }
  }
}
</style>
