<template>
  <div class="wrapper">
    <div class="imgContent">
      <!-- 单张图 -->
      <div v-if="pictureList.length == 1" class="oneImgCon">
        <div v-for="(imgItem, index) in pictureList" :key="index + 'only'" @click="lookBigPicture(index)">
          <img :src="imgItem.url" alt="" />
        </div>
      </div>
      <!-- 4张图 -->
      <div v-else-if="pictureList.length == 4" class="moreImgCon">
        <div
          v-for="(imgItem, index) in pictureList"
          :key="index + 'moreL'"
          class="moreImgPer"
          @click="lookBigPicture(index)"
        >
          <img :src="imgItem.url" alt="" />
        </div>
      </div>
      <!-- 多张图 -->
      <div v-else class="moreImgCon">
        <div
          v-for="(imgItem, index) in pictureList"
          :key="index + 'deblou'"
          class="moreImgPer"
          @click="lookBigPicture(index)"
        >
          <img :src="imgItem.url" alt="" />
        </div>
      </div>
    </div>

    <!-- 大图查看 -->
    <van-image-preview
      v-model="showBigImg"
      :closeable="true"
      :images="images"
      @change="onChange"
    >
      <template v-slot:index>第{{ indexPhoto }}页</template>
    </van-image-preview>
  </div>
</template>

<script>
import { ImagePreview } from "vant";

export default {
  props: ["pictureList"],
  data() {
    return {
      list: [], // 图片列表
      images: [],
      showPhoto: false, // 图片轮播显示
      indexPhoto: 0,
      showBigImg: false,
    };
  },
  mounted() {
    this.list = this.pictureList;
    // window.addEventListener(
    //   "popstate",
    //   () => {
    //     if (this.showBigImg) {
    //       this.showBigImg = false;
    //     }
    //   },
    //   false
    // );
  },
  // beforeDestroy() {
  //    window.removeEventListener(
  //     "popstate",
  //     () => {
  //       if (this.showBigImg) {
  //         this.showBigImg = false;
  //       } else {
  //         this.$router.go(-1);
  //       }
  //     },
  //     false
  //   );
  // },
  methods: {
    // 点击查看大图
    lookBigPicture(index) {
      console.log(index);
      // this.indexPhoto = index;
      this.transImg();
      // this.showBigImg = true;
      // window.history.pushState(null, null, "#/");
      ImagePreview({
        images: this.images,
        closeable: true,
        startPosition: index,
      });
    },

    // 图片滑动
    onChange(index) {
      this.index = index + 1;
    },

    // 转换数据格式
    transImg() {
      this.images = [];
      for (let i in this.pictureList) {
        this.images.push(this.pictureList[i].url);
      }
    },
  },
};
</script>
<style>
.van-image-preview__close-icon--top-right {
  /* bottom: 10px; */
  /* left: 50%;
  transform: translate(-50%); */
}
.van-image-preview__close-icon {
  position: absolute;
  z-index: 1;
  /* left: 50%;
  bottom: 0;
  transform: translate(-20px); */
  color: #c8c9c9;
  font-size: 40px;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.wrapper {
  .imgContent {
    width: 100%;
    margin-top: 7px;
    .oneImgCon {
      img {
        max-width: 100%;
        max-height: 150px;
      }
    }
    .moreImgCon {
      display: flex;
      flex-wrap: wrap;
      .moreImgPer {
        width: 32%;
        padding-bottom: 23.14%;
        position: relative;
        margin-right: 2%;
        margin-bottom: 2%;
        &:nth-child(3n) {
          margin-right: 0px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
